/* --------------------------------- */
/* EUROPE DIRECT BLOCK
------------------------------------ */

.eu-direct-block {
  p{
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 0 !important;
  }
  a {
    color: $blue;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
    &:hover, &:focus {
      text-decoration-thickness: 2px;
      text-underline-offset: 6px;
    }
    &:focus {
      outline: solid 2px $blue-focus;
      outline-offset: 4px;
    }
  }
}