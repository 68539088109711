/* NAVIGATION FOOTER ======== */
/* ========================== */

nav.navFooter {
  .footerLinks {
      li {
        a {
          text-decoration: none;
          color: $black;
          &.current[aria-current="page"],
          li.current[aria-current="true"] a {
            color: $blue;
            position: relative;
            &:after {
              content: "";
              position: absolute;
              bottom: -2px;
              left: 0;
              height: 1px;
              display: block;
              width: 100%;
              background-color: $blue;
            }
              &:after {
                background-color: $blue;
              }
          }
          &:hover, &:focus {
            text-decoration: underline;
            text-decoration-color: $blue;
            color: $blue;
            text-decoration-thickness: 1px;
            text-underline-offset: 6px;
          }
          &:focus {
            outline-offset: 5px;
          }
            &:after {
              background-color: $blue !important;
            }
        }
      }
    }
}
