/* svg */

.btn-etr {
  background-color: $blue !important;
  border: solid 2px $blue !important;
  color: $white;
  padding: 15px !important;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  transition: all .1s ease;
  font-weight: 600;
  &:hover, &:focus {
    background-color: #003a79 !important;
    color: $white !important;
  }
  &:focus {
    outline-offset: 1px;
  }
}