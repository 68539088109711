/* -------------------------------------- */
/* AFTER ELECTIONS
----------------------------------------- */

.ae-page {
  /*h1 {
    margin-bottom: 1.125rem;
  }
  h2 {
    font-family: "EPGammaBold", Arial, sans-serif;
    font-size: 1.75rem;
    line-height: 2rem;
    text-align: left;
    margin-bottom: 1.125rem;
  }
  h3 {
    font-family: "EPGammaBold", Helvetica, Arial, sans-serif;
    font-size: 1.125rem;
  }*/
  a {
    text-decoration: underline;
    color: $blue;
    text-decoration-thickness: 1px;
    &:hover, &:focus {
      text-decoration-thickness: 2px;
    }
  }
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .left-column {
    margin-right: 50px;
    .chapo {
      padding-left: 1rem;
      .card-com {
        margin-bottom: 4rem; //80px
        .card-com-content {
          p {
            margin: 0;
          }
        }
        @media screen and (max-width: 767.5px) {
          img {
            display: none;
          }
        }
      }
      @media screen and (max-width: 767.5px) {
        .card-com {
          margin-bottom: 2rem;
        }
      }
    }
    .portfolio-list {
      p {
        margin-top: 0;
      }
      .portfolio-list-items {
        li {
          &::marker {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 769px) {
    .left-column {
      margin-right: 0;
    }
  }
  .sidebar {
    max-width: 280px;
    padding-top: 110px;
    padding-bottom: 40px;
    padding-left: 15px;
    h2 {
      font-size: 1.5rem !important;
      line-height: 1.8rem !important;
      margin-bottom: 1.667rem; //30px
    }
    .sidebar-list-wrapper {
      .sidebar-list-item {
        margin-bottom: 1rem;
        a {
          display: inline-block;
          h3 {
            font-size: 1.125rem //18px;
          }
        }
        p {
          font-size: 1rem; //16px
          margin-top: 0;
          margin-bottom: 0.15rem;
        }
        .portfolio-list-items {
          padding-top: 0.25rem;
          li {
            font-size: 1rem; //16px
            line-height: 1.3rem;
            margin: 0 0 0.15rem 1rem;
            list-style: disc;
            &::marker {
              font-size: 0.7rem;
            }
          }
        }
      }
    }

  }

  @media screen and (max-width: 768px) {
    .sidebar {
      padding-top: 60px;
    }
  }
}