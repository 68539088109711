/* BTN SHARE */

.expand-social-share {
  a {
    color: $blue;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
    &:hover, &:focus {
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
      outline-offset: 1px;
    }
    &:focus {
      outline: solid 2px $blue-focus;
      outline-offset: 1px;
    }
  }
  button {
    &.cursor-pointer {
      &:focus {
        outline-color: $blue-focus;
      }
    }
  }
  .social-list {
    li {
      display: inline-block;
      list-style: none;
      a {
        svg {
          border: solid 1px $blue;
          &.twitter {
            border-color: $blue;
            fill: $blue;
          }
          border-radius: 100%;
          .back {
            fill: $white;
            transition: .1s all ease-out;
          }
          .icon {
            fill: $blue;
            transition: .1s all ease-out;
          }
        }
        &:hover, &:focus {
          svg {
            border: solid 1px $white;
            &.twitter {
              background-color: $blue;
              border-color: $blue;
            }
            .back {
              fill: $blue;
            }
            .icon {
              fill: $white;
            }
          }
        }
      }
    }
  }
}