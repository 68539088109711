/* NAVIGATION =============== */
/* ========================== */

.navList{
  li{
    a{
      text-decoration: none;
      color: $black;
      &:hover,
      &:focus {
        color: $blue;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
      }
      &:focus{
        outline: solid 2px $blue;
        outline-offset: 1px;
      }
    }
  }
  .current{
    color: $blue;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      height: 2px;
      display: block;
      width: 100%;
      background-color: $blue;
    }
  }
  &.show{
    animation-name: slidedown;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-direction: alternate;
    top: 0;
    opacity: 1;
    display: block;
  }
}

@keyframes slidedown{
  0% {
    top: -300px;
    display: none;
  }
  10%{
    display: flex;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

/* Menu component */
nav {
  &.default-menu, &.lang-nav {
    display: flex;
    justify-content: flex-end;
    .expand-collapse {
      flex: 0 0 auto;
      margin-left: 10px;
      .nav-button {
        &[aria-expanded="false"] {
          svg {
            rotate: 0;
            transition: all .15s ease-out;
          }
        }
        &[aria-expanded="true"] {
          svg {
            rotate: 180deg;
            transition: all .15s ease-out;
          }
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

nav.lang-nav {
  .expand-collapse {
    button {
      &:hover {
        border-color: #024EA2;
      }
    }
    .list {
      display: none;
      width: 100%;
      list-style: none;
      padding-top: 1rem;

      &[aria-labelledby="er-menu"] {
        position: absolute;
        top: calc(2rem + .4rem);
        left: 0;
        width: 100%;
        max-width: 100vw;
        padding: 2rem;
        background-color: #fff;
        z-index: 2;

        .menu-inner-wrapper {
          max-width: 120rem;
          display: flex;
          flex-direction: column;
          margin: 0 auto;

          .column {
            padding: 0 0 20px 0;
            .sub-nav {
              li {
              padding: 0;
              }
            }
            .sub-nav-heading {
              font-size: 1.2rem;
              line-height: 1.4rem;
              font-variation-settings: 'wght' var(--text-wght-sb);
              margin-bottom: 1rem;
            }
          }

        }
      }

      li {
        padding: 0.25rem 1rem;
        a {
          font-size: 0.95rem;
        }
          &.current[aria-current="page"],
          &.current[aria-current="true"] a {
            color: $blue;
            text-decoration: none;
            position: relative;
            &:after {
              content: "";
              position: absolute;
              bottom: -3px;
              left: 0;
              height: 1px;
              display: block;
              width: 100%;
              background-color: $blue;
            }
          }
          &:hover, &:focus {
            text-decoration: none;
            text-decoration-thickness: 2px;
            color: $blue;
          }
          &:focus {
            outline-offset: 5px;
          }

      }
    }
  }
}

nav.default-menu {
  .expand-collapse {
    .list {
      display: none;
      width: 100%;
      list-style: none;
      padding-top: 1rem;
      li {
        padding: 0.25rem 1rem;
        a {
          font-size: 0.9rem;
          text-decoration: none;
          color: #333333;
          &.current[aria-current="page"],
          li.current[aria-current="true"] {
            a {
              color: $blue;
              position: relative;
              &:after {
                content: "";
                position: absolute;
                bottom: -3px;
                left: 0;
                height: 2px;
                display: block;
                width: 100%;
                background-color: $blue;
              }
            }
          }
          &:hover, &:focus {
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: 6px;
            color: $blue;
          }
          &:focus {
            outline-offset: 5px;
          }
        }
      }
    }
    .nav-button {
      &:hover {
        border-color: $blue;
      }
    }
  }
}

nav.default-menu, nav.lang-nav {
  .expand-collapse {
    .list {
      display: none;
      width: 100%;
      list-style: none;
      padding-top: 1rem;
      margin-top: 0.1rem;
      li {
        padding: .25rem .5rem;
        a {
          text-decoration: none;
          color: $black;
          &.current[aria-current="page"],
          li.current[aria-current="true"] a {
            color: $blue;
            font-weight: 400;
            position: relative;
            &:after {
              content: "";
              position: absolute;
              bottom: -3px;
              left: 0;
              height: 2px;
              display: block;
              width: 100%;
              background-color: $blue;
            }
              &:after {
                background-color: $blue;
              }
          }
          &:hover, &:focus {
            text-decoration: underline;
            color: $blue;
          }
          &:hover, &:focus {
            text-decoration-thickness: 1px;
          }
          &:focus {
            outline-offset: 5px;
          }
            &:after {
              background-color: $blue !important;
            }
        }
      }
    }
  }
}

nav.default-menu, nav.lang-nav {
  .expand-collapse {
    .list[hidden] {
      position: relative;
      left: inherit;
      width: auto;
    }
    .list:not([hidden]) {
      &#menu1-list {
        background-color: $white;
      }
      position: absolute;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      max-width: 1010px;
    }
  }
}

nav.lang-nav {
  .expand-collapse {
      .list {
        column-count: 4;
        a {
          font-size: 0.9rem;
        }
      }
    .list:not([hidden]) {
      /*display: flex !important;
      flex-wrap: wrap !important;*/
      display: block !important;
      margin: 0.5rem 0.25rem 0 0.25rem;
    }
  }
}
@media screen and (max-width: 1079px) {
  nav.default-menu {
    .expand-collapse {
      .list {
        li {
          a {
            &.item-collapsed {
            display: flex;
            justify-content: space-between;
              align-items: center;
            }
            svg {
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1080px) {
  nav.default-menu {
    .expand-collapse {
      .list {
        display: flex !important;
        position: relative !important;
        padding-top: 0;
      }
      .nav-button {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  nav.lang-nav {
    .expand-collapse {
      .list {
        column-count: 3;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  nav.lang-nav {
    .expand-collapse {
      .list {
        column-count: 2;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  nav.lang-nav {
    .expand-collapse {
      .list {
        column-count: 1;
      }
    }
  }
}
/* Sticky position */
.sticky {
  position: fixed;
  top: 0;
  bottom: auto;
  width: 100%;
  z-index: 100;
  background-color: #fff;
}

/* Menu revamp :: February 2024 */

  .navWrapper {
      &[role="dialog"] {
      position: fixed;
      left: 0;
      top: 0;
      background-color: #fff;
      width: 100%;
      height: 100%;
      z-index: 10000;
      overflow: auto;
      .divNavigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        background-color: rgba(200,200,200,0.10);
        width: 100%;
        top: 0;
        left: 0;
        min-height: 57px;
        z-index: 10001;
      .divBack {
        a {
          &[data-expanded="true"] {
            background-color: $blue;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 57px;
            min-height: 57px;
            text-decoration: none;
            font-size: 1.5rem;
            font-variation-settings: "wght" var(--text-wght-sb);
            &:focus {
              outline-offset: -4px;
              outline-color: $white;
            }
            svg {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
      .divMenuTitle {
        flex: auto;
        padding: 5px 15px;
        font-size: 1.125rem;
        font-variation-settings: "wght" var(--text-wght-sb);
      }
    }
      .main-menu {
        display: block;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 55px;
          width: 100%;
          position: relative;
          &::after {
            display: block;
            content: "";
            width: 100%;
            height: 0.05rem;
            position: absolute;
            bottom: 0;
            background-color: $grey-border;
          }
          &.button {
            ul {
              width: 100%;
            }
          }
          a{
            font-size: 0.9rem;
            color: $blue;
            text-decoration: underline;
            &.current[aria-current="page"],
            li.current[aria-current="true"] a {
              color: $blue;
              font-weight: 400;
              text-decoration: none;
              position: relative;
              &:after {
                content: "";
                position: absolute;
                bottom: 1px;
                left: 0;
                height: 2px;
                display: block;
                width: 100%;
                background-color: $blue;
              }
            }
            &:hover,
            &:focus {
              color: $blue;
              text-decoration: underline;
              text-decoration-thickness: 1px;
              text-underline-offset: 4px;
            }
            &:hover {
              text-decoration: underline;
              text-decoration-thickness: 2px;
            }
            &:focus{
              outline: solid 2px $blue;
              outline-offset: 3px;
              outline-color: $blue-focus;
            }
            &[data-expanded="false"] {
              color: $black;
              text-decoration: none;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            }
            svg {
              transform: rotate(-90deg);
            }
          }
      }
    }
      .divmainMenuButton {
        #mainMenuButton {
          width: 57px;
          height: 57px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $blue;
          text-decoration: none;
          font-size: 1.2rem;
          font-variation-settings: "wght" var(--text-wght-sb);
          &:focus {
            outline-offset: -2px;
          }
        }
      }
    }
    &[role="navigation"] {
      display: flex;
      justify-content: center;
      align-items: center;
      .divmainMenuButton {
        #mainMenuButton {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $black;
          text-decoration: none;
          font-variation-settings: "wght" var(--text-wght-r);
          margin-right: 0.5rem;
          padding-top: 0.3rem;
          padding-right: 0.625rem;
          padding-bottom: 0.15rem;
          padding-left: 0.625rem;
          border: solid 1px $grey-border;
          border-radius: 0.375rem;
          &:hover {
            border-color: $blue;
          }
          &:focus {
            outline-offset: 0;
          }
        }
      }
    }
  }

.desktop-menu {
  .er-menu {
    span {
      font-size: 0.9rem;
    }
    &[aria-expanded="false"] {
      svg {
        transition: all 0.15s ease-out;
        rotate: 0deg;
      }
    }
    &[aria-expanded="true"] {
      svg {
        transition: all 0.15s ease-out;
        rotate: 180deg;
      }
    }
  }
  .default-menu {
    .desktop-menu-display {
      display: flex;
      align-items: center;
      .navList {
        display: flex;
        flex-direction: row;
        align-items: center;
        li{
          margin-right: 1rem;
          a {
            font-size: 0.9rem;
            text-decoration: none;
            color: $black;
            &:hover,
            &:focus {
              color: $blue;
              text-decoration: underline;
              text-decoration-thickness: 1px;
              text-underline-offset: 7px;
            }
            &:focus{
              outline: solid 2px $blue-focus !important;
              outline-offset: 5px;
            }
          }
        }
        .current{
          color: $blue;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            bottom: 1px;
            left: 0;
            height: 2px;
            display: block;
            width: 100%;
            background-color: $blue;
          }
          a {
            color: $blue !important;
          }
        }
        .er-menu {
          &:focus{
            outline: solid 2px $blue-focus !important;
            outline-offset: 1px;
          }
          span {
            font-size: 0.9rem;
            &:hover,
            &:focus {
              color: $blue !important;
              text-decoration: underline;
              text-decoration-thickness: 1px;
              text-underline-offset: 7px;
            }
          }
        }
      }
    }
  }
}
.submenu-content {
  display: block;
  margin-top: 20px;
  width: 100%;
  .menu-inner-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
    .column {
      margin: 0;
      padding: 0.5rem 0;
      .column_countries {
        columns: 2;
        column-gap: 5rem;
      }
      .sub-nav {
          margin: 0.5rem 0 1rem 0;
          li {
            font-size: 0.9rem;
            line-height: 2rem;
          }
      }
      .sub-nav-heading {
        font-variation-settings: 'wght' var(--text-wght-sb);
      }
    }
  }
  .lang-content {
    column-count: 4;
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      column-count: 3;
    }
    @media screen and (max-width: 480px) {
      column-count: 2;
    }
    @media screen and (max-width: 320px) {
      column-count: 1;
    }
    li {
      padding: 0.4rem 0;
      a{
        display: inline-block;
        font-size: 0.9rem;
        text-decoration: none;
        color: $black;
        &:hover,
        &:focus {
          color: $blue;
          text-decoration: underline;
          text-decoration-thickness: 1px;
          text-underline-offset: 5px;
        }
        &:focus{
          outline: solid 2px $blue-focus !important;
          outline-offset: 2px;
        }
      }
    }
    .current{
      color: $blue;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 1px;
        left: 0;
        height: 2px;
        display: block;
        width: 100%;
        background-color: $blue;
      }
      a {
        color: $blue !important;
      }
    }
  }
}

.desktop-menu {
  display: flex;
}
.mobile-menu {
  display: none;
}
@media screen and (max-width: 1080px) {
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: flex;
  }
}

