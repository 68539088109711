/* ---------------------------------------- */
/* CARDS LINKS POLITICAL GROUPS FOR ELECTIONS
------------------------------------------- */

.epol-block ul li a {
  display: inline-block;
  color: $blue;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 6px;
  &:hover, &:focus {
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;
    outline-offset: 1px;
  }
  &:focus {
    outline: solid 2px $blue-focus;
    outline-offset: 4px;
  }
}