/* ----------------------- -- */
/* TOGETHER.EU - RELATED STUFF
----------------------------- */

/* Together.eu Homepage banner */
.together-banner {
  max-width: 866px !important;
  a {
    text-decoration: underline;
    color: $blue;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &:hover, &:focus {
      text-decoration-thickness: 2px;
      text-underline-offset: 6px;
    }
    &:focus {
      outline: solid 2px $blue;
      outline-offset: 4px;
      text-decoration-thickness: 2px;
      text-underline-offset: 6px;
    }
  }
  img {
    width: 228px;
  }
}